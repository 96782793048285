import React from 'react';
import PropTypes from 'prop-types';

import HomePage from '../pagesComponents/homePage';
import Layout from '../components/layout';

const Home = ({ location }) => (
  <Layout location={location}>
    <HomePage />
  </Layout>
);

Home.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Home;
